import React, { useEffect, useState } from "react";
import styles from "./landingPage.module.scss";
import logo from "../../assets/logo/logo.png";
import fbLogo from "../../assets/socialIcons/Facebook.png";
import igLogo from "../../assets/socialIcons/Instagram.png";
import ytLogo from "../../assets/socialIcons/YouTube.png";
import liLogo from "../../assets/socialIcons/linkedin.png";
import emailLogo from "../../assets/socialIcons/Email.png";

const LandingPage = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date("December 18, 2024 10:00:00").getTime();
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(timer);
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.landingPage}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.subHeadingContainer}>
        <h2 className={styles.subHeading}>Launching Soon</h2>
      </div>
      <div className={styles.timer}>
        <div className={styles.timeContainer}>{`${timeLeft.days}d`}</div>{" "}
        <div className={styles.timeContainer}>{`${timeLeft.hours}h`}</div>{" "}
        <div className={styles.timeContainer}> {`${timeLeft.minutes}m`}</div>{" "}
        <div className={styles.timeContainer}>{`${timeLeft.seconds}s`}</div>
      </div>
      <div className={styles.socialLinks}>
        <a
          href="https://facebook.com/craftsbuy.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={fbLogo}
            alt="CraftsBuy Facebook Page Link"
            className={styles.iconLogo}
          />
        </a>
        <a
          href="https://instagram.com/craftsbuy.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={igLogo}
            alt="CraftsBuy Instagram Channel Link"
            className={styles.iconLogo}
          />
        </a>
        <a href="https://www.youtube.com/@craftsbuy" target="_blank" rel="noopener noreferrer">
          <img
            src={ytLogo}
            alt="CraftsBuy YouTube Channel Link"
            className={styles.iconLogo}
          />
        </a>
        <a href="https://www.linkedin.com/company/craftsbuy/about/" target="_blank" rel="noopener noreferrer">
          <img
            src={liLogo}
            alt="CraftsBuy Linked In Page Link"
            className={styles.iconLogo}
          />
        </a>
        <a href="mailto:info@craftsbuy.in">
          <img
            src={emailLogo}
            alt="CraftsBuy Email ID"
            className={styles.iconLogo}
          />
        </a>
      </div>{" "}
      <div className={styles.blurLayer}></div>
    </div>
  );
};

export default LandingPage;
