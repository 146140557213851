
import LandingPage from "./pages/LandingPage/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUsPage from "./pages/contactUsPage/ContactUsPage";

function App() {
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<LandingPage />} />
    //     <Route path="/contact-us" element={<LandingPage/>}/>
    //   </Routes>
    // </BrowserRouter>
    <LandingPage/>
  );
}

export default App;
